import React from "react"
import { graphql } from "gatsby"
import { getImage, getSrc } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from 'styled-components'

import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import HeroSection from "../components/common/hero-section"
import htmlSerializer from "../utils/htmlSerializer"

const StyledDiv = styled.div`
  background-image: linear-gradient(to bottom, #679898, #f0f5f5);
`

const ProductGuidePageTemplate = ({ data }) => {
  const doc = data.prismicProductGuidePage
  const imgSrc = getSrc(doc.data.page_hero_background)
  const imgHero = getImage(doc.data.page_hero_background)

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title ? doc.data.page_title : null}
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
      />

      <HeroSection
        title={doc.data.page_title ? doc.data.page_title : 'Page Title'}
        imgSrc={imgHero}
      />

      <StyledDiv className="py-20">
        <div className="intro-section w-11/12 lg:w-10/12 max-w-screen-lg mx-auto md:px-12">
          {doc.data.intro_section_content
            ? 
            <RichText
              render={doc.data.intro_section_content.richText}
              htmlSerializer={htmlSerializer}
            />
            : 
            <p className="text-white text-lg md:text-xl text-center md:px-12 mb-6">Intro section content here and more..</p>
          }
        </div>

        <div className="w-11/12 max-w-screen-xl mx-auto mt-10">
          <iframe title="SRA Product Guide" src={doc.data.product_guide_source_link ? doc.data.product_guide_source_link : 'https://player.flipsnack.com?hash=Q0Q4QkU1RUVGQjUrdGlxMXF3ZTNmMg=='} width="100%" height="600" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen />
        </div>
      </StyledDiv>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(ProductGuidePageTemplate)

export const ProductGuidePageTemplateNewQuery = graphql`
  query($id: String!) {
    prismicProductGuidePage(id: { eq: $id }) {
      _previewable
      id
      uid
      data {
        page_title
        page_short_description
        page_hero_background {
          gatsbyImageData(
            width: 1920
          )
        }
        intro_section_content {
          richText
        }
        product_guide_source_link
      }
    }
  }
`