import React, { useEffect } from "react";

const ElfsightEmbed = () => {
  useEffect(() => {
    // Dynamically load the Elfsight script
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup by removing the script if the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Embed the Elfsight div */}
      <div
        className="elfsight-app-b27beca2-ce38-457d-83c2-42127c50fcf0"
        data-elfsight-app-lazy
      ></div>
    </div>
  );
};

export default ElfsightEmbed;