import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { MenuIcon } from '@heroicons/react/outline'

import LogoSimple from "../logo-simple"
import MenuItemsUpdate from "./menu-items-update"
import AnnouncementBar from "../common/AnnouncementBar"
import { doc } from "prettier"

const MobileDrawerFullNew = loadable(() =>
  pMinDelay(import('./mobile-drawer-full-new'), 2000),{
    fallback:
      <div className="w-2/3 xl:hidden flex justify-end">
        <button
          className="text-site-white focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent mr-2 p-0"
        >
          <span className="sr-only">Open panel</span>
          <MenuIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>
  }
)

const HeaderFull = ({ isContactPage }) => {
  const data = useStaticQuery(graphql`
    query HeaderFullQuery {
      prismicAnnouncementBar {
        data {
          activate_announcement_bar
          title
          link_text
          link_url {
            uid
            link_type
            url
          }
        }
      }
    }
  `);

  const docs = data.prismicAnnouncementBar;

  return (
    <div className="w-full fixed z-50">
      {docs.data.activate_announcement_bar
       ?
        <AnnouncementBar 
          title={doc.data.title}
          linkText={docs.data.link_text}
          linkType={docs.data.link_url.link_type}
          linkURL={docs.data.link_url.url}
          linkUID={docs.data.link_url.uid}
        />
        :
        null
      }

      <div className="w-full bg-site-gray-dark pt-0 lg:pt-0">
        <header
          className="bg-site-black w-full border-b-2 border-gray-500"
        >
          <nav className="w-11/12 max-w-screen-xl flex items-center justify-between mx-auto pt-2 pb-2">
            <div className="w-24 lg:w-36">
              <LogoSimple />
            </div>

            <MenuItemsUpdate
              isContactPage={isContactPage ? true : false}
            />

            <MobileDrawerFullNew 
              isContactPage={isContactPage ? true : false}
            />
          </nav>
        </header>
      </div>
    </div>
  )
}

export default HeaderFull
