import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from '@reach/router'
import styled from "styled-components"

const HeroSection831bPlanNewUpdate = ({ imgSrc, title, buttonName, buttonColor, buttonTextColor, buttonHoverColor, buttonHoverTextColor }) => {
  const data = useStaticQuery(graphql`
    query HeroSection831bPlanNewUpdateQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/831b-plan-hero-bg.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  const StyledButton = styled.button`
    background: #${buttonColor ? buttonColor : '1EA0A0'};
    color: #${buttonTextColor ? buttonTextColor : 'FFFFFF'};

    :hover {
      background: #${buttonHoverColor ? buttonHoverColor : '35AAAA'};
      color: #${buttonHoverTextColor ? buttonHoverTextColor : 'FFFFFF'};
    }
  `

  return (
    <>
      <div className="relative grid pt-14 xl:pt-20" style={{ background: "#000000", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 400,
          }}
          alt="hero background"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
            <div className="flex flex-col justify-center">
              <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                {title}
              </h2>

              <div className="flex flex-col items-center justify-center mt-6 xl:mt-8">
                <StyledButton
                  onClick={() => {
                    const element = document.getElementById('plan-options');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="w-full md:w-auto font-display text-base md:text-lg tracking-wider px-8 py-3"
                >
                  {buttonName ? buttonName : 'Button Name'}
                </StyledButton>
              </div>
            </div>
          </div>
        </div>     
      </div>
    </>
  )
}

export default HeroSection831bPlanNewUpdate
