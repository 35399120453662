import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const AnnouncementBar = ({ title, linkText, linkType, linkURL, linkUID }) => (
  <div className='bg-site-red'>
    <div className="w-full flex items-center justify-center py-1 mx-auto">
      <h2 className='font-display text-white text-sm md:text-base text-center tracking-widest'>
        {title ? title : "Announcement Title"} &#62;&#62;&#62; {linkType === "Document" || linkType === "Any"
            ?
            <>
              <Link className='hidden lg:inline-block hover:underline font-medium uppercase' to={linkUID ? `/${linkUID}` : "/"}>
                {linkText ? linkText : 'Link Text'}
              </Link>
              <Link className='inline-block lg:hidden underline font-medium uppercase' to={linkUID ? `/${linkUID}` : "/"}>
                {linkText ? linkText : 'Link Text'}
              </Link>
            </>
            :
            linkType === "Web" || linkType === "Media"
            ?
            <>
              <a className='hidden lg:inline-block hover:underline font-medium uppercase' href={linkURL ? linkURL : 'https://www.831b.com/'} target="_blank" rel='noopener noreferrer'>{linkText ? linkText : 'Link Text'}</a>
              <a className='inline-block lg:hidden underline font-medium uppercase' href={linkURL ? linkURL : 'https://www.831b.com/'} target="_blank" rel='noopener noreferrer'>{linkText ? linkText : 'Link Text'}</a>
            </>
            :
            <>
              <a className='hidden lg:inline-block hover:underline font-medium uppercase' href='https://www.831b.com/' target="_blank" rel='noopener noreferrer'>Learn More</a>
              <a className='inline-block lg:hidden underline font-medium uppercase' href='https://www.831b.com/' target="_blank" rel='noopener noreferrer'>Learn More</a>
            </>
          }          
      </h2>
    </div>
  </div>
)

AnnouncementBar.propTypes = {
  shoutText: PropTypes.string,
  title: PropTypes.string,
  linkURL: PropTypes.string,
  linkText: PropTypes.string,
}

export default AnnouncementBar