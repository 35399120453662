import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { RichText } from "prismic-reactjs"
import HubspotForm from 'react-hubspot-form'
import styled from "styled-components"

import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"
import htmlSerializer from "../utils/htmlSerializer"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { VimeoPlayer } from 'reactjs-vimeo-player'
import Carousel from "../components/common/carousel"

const StyledBgGray = styled.div`
  --tw-bg-opacity: 1;
  background-color: rgba(243,244,246,var(--tw-bg-opacity));
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const StyledBgWhite = styled.div`
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--tw-bg-opacity));
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const PageSymposiumTemplate = ({ data }) => {
  const doc = data.prismicSymposium
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)
  const imgDefault = getImage(data.imgDefault)
  const photoPlaceholder = getImage(data.photoPlaceholder)

  const imgAgenda = getImage(doc.data.agenda_background)
  const bgImage = convertToBgImage(imgAgenda)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.page_short_description}
        image={imgSrc}
      />

      <div className="relative pt-14 xl:pt-20" style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 400,
          }}
          alt="hero background"
          image={imgHero ? imgHero : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
            <div className="flex flex-col justify-center">
              <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                {doc.data.page_title ? doc.data.page_title : 'Page Title'}
              </h2>

              {doc.data.hero_section_button_link_type
                ?
                <div className="w-full flex items-center justify-center mt-8">
                  {doc.data.button_link.link_type === "Document" || doc.data.button_link.link_type === "Any"
                    ?
                      <Link to={doc.data.button_link.uid ? doc.data.button_link.uid : `/`}>
                        <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                          {doc.data.button_name ? doc.data.button_name : "Button Name"}
                        </button>
                      </Link>
                    :
                    doc.data.button_link.link_type === "Web" || doc.data.button_link.link_type === "Media"
                    ?
                      <a
                        href={doc.data.button_link.url ? doc.data.button_link.url : `/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                          {doc.data.button_name ? doc.data.button_name : "Button Name"}
                        </button>
                      </a>
                    :
                    null
                  }
                </div>
                :
                <div className="w-full flex items-center justify-center mt-8">
                  <a>
                    <button
                      onClick={() => {
                        const element = document.getElementById('form/');
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth' });
                        }
                      }}
                      className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3"
                    >
                      {doc.data.button_name ? doc.data.button_name : "Button Name"}
                    </button>
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {doc.data.red_bubble_text ? (
        <div className="relative">
          <div className="w-10/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto pt-12 pb-6 px-6 md:px-16 -mt-8 md:-mt-16 z-20 rounded-3xl shadow-lg">
            <div className="text-white font-medium text-center text-lg md:text-xl">
              <RichText
                render={doc.data.red_bubble_text.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="w-11/12 max-w-screen-lg mx-auto font-medium text-center text-lg md:text-xl py-20 space-y-6 px-4 md:px-0">
        {doc.data.symposium_description ? (
          <RichText
            render={doc.data.symposium_description.richText}
            htmlSerializer={htmlSerializer}
          />
        ) : (
          `&nbsp;`
        )}
      </div>

      <div id="form/" className="w-full flex flex-col items-center justify-center bg-site-gray-light pt-32 pb-24 px-2 md:px-0">
        <div className="w-11/12 bg-white border-site-gray-dark text-black max-w-screen-md mx-auto text-center rounded-3xl py-10 flex flex-col items-center">
          <div className="bg-site-green text-white font-bold mx-auto py-6 px-6 md:px-24 uppercase rounded-full tracking-wider text-lg -mt-20">
            {doc.data.form_section_title ? doc.data.form_section_title : "Register"}
          </div>

          <div className="w-10/12 md:w-8/12 mt-12">
            <HubspotForm
              portalId='23182726'
              formId={doc.data.form_id}
              loading={<div className='italic'>Loading request form...</div>}
            />
          </div>
        </div>
      </div>

      {doc.data.agenda_section_shown
        ?
        <BackgroundImage
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
          className="w-full flex flex-col items-center justify-center py-24 bg-blend-darken bg-black bg-opacity-20 px-2 md:px-0"
        >
          <div className="w-11/12 bg-white border-site-gray-dark text-black max-w-2xl mx-auto text-center rounded-3xl py-10 flex flex-col items-center">

            <div className="bg-site-green text-white font-bold mx-auto py-6 px-12 md:px-24 rounded-full tracking-wider text-lg -mt-20">
              AGENDA
            </div>

            <div className="w-11/12 mx-auto mb-4">
              {doc.data.agenda
                ? doc.data.agenda.map((agenda, index) => {
                    return (
                      <div key={`agenda${index}`} className="text-lg mt-4">
                        {agenda.title ? (
                          <div className="text-site-red font-bold mt-10">
                            {agenda.title}
                          </div>
                        ) : null}
                        <div className="font-bold pt-2">{agenda.activity}</div>
                        <RichText
                          render={agenda.activity_details.richText}
                          htmlSerializer={htmlSerializer}
                        />
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
        </BackgroundImage>
        :
        null
      }

      {doc.data.presenters_section_shown
        ?
        <div className="w-full flex flex-col">
          <div className="w-11/12 mx-auto pt-8 sm:pt-24">
            <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center mt-4 mb-8 uppercase">
              About our Presenters
            </h2>
          </div>

          {doc.data.bio_section.map((presenter, index) => {
            const teamPhoto = getImage(presenter.photo)
            return (
              <div>
                {presenter.section_background
                  ?
                  <StyledBgGray
                    key={`presenter-${index}`}
                  >
                    <div className="relative w-10/12 md:w-8/12 xl:w-10/12 max-w-screen-lg flex flex-col xl:flex-row items-center justify-center mx-auto xl:space-x-6">
                      <div className="w-full xl:w-4/12 flex justify-center p-6">
                        <GatsbyImage
                          image={teamPhoto ? teamPhoto : photoPlaceholder}
                          alt={
                            presenter.photo.alt
                              ? presenter.photo.alt
                              : `presenter${index}`
                          }
                          className="rounded-lg shadow-lg"
                        />
                      </div>
                      <div className="w-full xl:w-8/12">
                        <div className="mb-2">
                          <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                            {presenter.name ? presenter.name : "Team Name"}
                          </h2>
                        </div>
                        <div className="lg:w-8/12 mx-auto border-t-2 border-site-gray-dark pt-2 mb-8">
                          <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                            {presenter.position ? presenter.position : "Position"}
                          </h3>
                        </div>

                        <p className="text-base">
                          {presenter.short_bio
                            ? presenter.short_bio
                            : "Short bio should go here."}
                        </p>
                      </div>
                    </div>
                  </StyledBgGray>
                  :
                  <StyledBgWhite
                    key={`presenter-${index}`}
                  >
                    <div className="relative w-10/12 md:w-8/12 xl:w-10/12 max-w-screen-lg flex flex-col xl:flex-row items-center justify-center mx-auto xl:space-x-6">
                      <div className="w-full xl:w-4/12 flex justify-center p-6">
                        <GatsbyImage
                          image={teamPhoto ? teamPhoto : photoPlaceholder}
                          alt={
                            presenter.photo.alt
                              ? presenter.photo.alt
                              : `presenter${index}`
                          }
                          className="rounded-lg shadow-lg"
                        />
                      </div>
                      <div className="w-full xl:w-8/12">
                        <div className="mb-2">
                          <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                            {presenter.name ? presenter.name : "Team Name"}
                          </h2>
                        </div>
                        <div className="lg:w-8/12 mx-auto border-t-2 border-site-gray-dark pt-2 mb-8">
                          <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                            {presenter.position ? presenter.position : "Position"}
                          </h3>
                        </div>

                        <p className="text-base">
                          {presenter.short_bio
                            ? presenter.short_bio
                            : "Short bio should go here."}
                        </p>
                      </div>
                    </div>
                  </StyledBgWhite>
                }
              </div>
            )
          })}

          {doc.data.show_button
            ?
            <div className="w-10/12 xl:w-full flex items-center justify-center mx-auto max-w-screen-xl mt-10 mb-14 md:mb-24">
              {doc.data.presenters_section_button_link.link_type === "Document" || doc.data.presenters_section_button_link.link_type === "Any"
                ?
                  <Link to={doc.data.presenters_section_button_link.uid ? doc.data.presenters_section_button_link.uid : `#form/`}>
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                      {doc.data.presenters_section_button_name ? doc.data.presenters_section_button_name : "Register Now"}
                    </button>
                  </Link>
                :
                doc.data.presenters_section_button_link.link_type === "Web" || doc.data.presenters_section_button_link.link_type === "Media"
                ?
                  <a
                    href={doc.data.presenters_section_button_link.url ? doc.data.presenters_section_button_link.url : `#form/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                      {doc.data.presenters_section_button_name ? doc.data.presenters_section_button_name : "Register Now"}
                    </button>
                  </a>
                :
                null
              }
            </div>
            :
            null
          }
        </div>
        :
        null
      }

      {doc.data.videos_section_shown || doc.data.pictures_section_shown ? (
        <div className="w-full flex flex-col bg-site-gray-light">
          <div className="w-11/12 mx-auto pt-8 sm:pt-24">
            <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center mt-4 uppercase">
              {doc.data.videos_title ? doc.data.videos_title : 'Video Title'}
            </h2>
          </div>
          {doc.data.videos_section_shown ? (
            <div className="w-11/12 max-w-screen-sm mx-auto pt-4">
              <h2 className="text-xl md:text-2xl text-center mt-4">
                {doc.data.videos_description ? doc.data.videos_description : 'Video Description'}
              </h2>
            </div>
          ) : null}

          {doc.data.videos_section_shown ? (
            <div className="w-10/12 mx-auto max-w-screen-xl grid grid-cols-1 md:grid-cols-2 px-4 md:px-10 py-4 md:py-10 md:gap-x-4 gap-y-2 md:gap-y-4">
              {doc.data.videos
                ? doc.data.videos.map((video, index) => {
                    return (
                      <VimeoPlayer
                        key={`video${index}`}
                        id={video.id}
                        width={640}
                        height={480}
                        responsive
                        className="shadow-lg mt-12"
                      />
                    );
                  })
                : null}
            </div>
          ) : null}

          {doc.data.pictures_section_shown ? (
            <div className="w-9/12 xl:w-10/12 mx-auto max-w-screen-xl mt-10 mb-14 md:mb-24">
              <Carousel>
                {doc.data.pictures.map((picture, index) => {
                  const imgData = getImage(picture.picture);
                  return (
                    <GatsbyImage
                      key={`image-${index}`}
                      image={imgData ? imgData : photoPlaceholder}
                      alt="image"
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : null}

          {doc.data.show_button1
            ?
            <div className="w-10/12 xl:w-full flex items-center justify-center mx-auto max-w-screen-xl mb-14 md:mb-24">
              {doc.data.videos_section_button_link.link_type === "Document" || doc.data.videos_section_button_link.link_type === "Any"
                ?
                  <Link to={doc.data.videos_section_button_link.uid ? doc.data.videos_section_button_link.uid : `#form/`}>
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                      {doc.data.videos_section_button_name ? doc.data.videos_section_button_name : "Register Now"}
                    </button>
                  </Link>
                :
                doc.data.videos_section_button_link.link_type === "Web" || doc.data.videos_section_button_link.link_type === "Media"
                ?
                  <a
                    href={doc.data.videos_section_button_link.url ? doc.data.videos_section_button_link.url : `#form/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                      {doc.data.videos_section_button_name ? doc.data.videos_section_button_name : "Register Now"}
                    </button>
                  </a>
                :
                null
              }
            </div>
            :
            null
          }
        </div>
      ) : null}
    </LayoutNew>
  )
}

export default withPrismicPreview(PageSymposiumTemplate)

export const PageSymposiumTemplateQueryNew1 = graphql`
  query($id: String!) {
    prismicSymposium(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        button_name
        hero_section_button_link_type
        button_link {
          uid
          link_type
          url
        }
        form_section_title
        form_id
        red_bubble_text {
          richText
        }
        symposium_description {
          richText
        }
        page_hero_background {
          gatsbyImageData(width: 1920)
        }
        agenda_background {
          gatsbyImageData(width: 1920)
        }
        agenda_section_shown
        presenters_section_shown
        show_button
        presenters_section_button_name
        presenters_section_button_link {
          uid
          link_type
          url
        }
        videos_section_shown
        pictures_section_shown
        agenda {
          activity
          title
          activity_details {
            richText
          }
        }
        bio_section {
          name
          position
          short_bio
          section_background
          photo {
            alt
            gatsbyImageData(width: 367)
          }
        }
        videos_description
        videos_title
        show_button1
        videos_section_button_name
        videos_section_button_link {
          uid
          link_type
          url
        }
        videos {
          id
        }
        pictures {
          picture {
            gatsbyImageData(width: 367)
          }
        }
      }
    }
    imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
        )
      }
    }
    photoPlaceholder: file(
      relativePath: { eq: "team/team-photo-placeholder.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 367)
      }
    }
  }
`