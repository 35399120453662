import { componentResolverFromMap } from "gatsby-plugin-prismic-previews";

import { linkResolver } from "./link-resolver";

import BlogPostTemplate from "../../src/pages/blog/{prismicBlogPost.uid}";
import PageSymposiumTemplate from "../../src/pages/{prismicSymposium.uid}";
import PageNewQualifyFormPageTemplate from "../../src/pages/{prismicNewQualifyFormPage.uid}";
import PageCaptiveVsMicroCaptiveLandingTemplate from "../../src/pages/{prismicCaptiveVsMicrocaptivePage.uid}";
import Page831bPlanTemplate from "../../src/pages/{prismicNew831BPlanPage.uid}";
import ProductGuidePageTemplate from "../../src/pages/{prismicProductGuidePage.uid}";

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_post: BlogPostTemplate,
      symposium: PageSymposiumTemplate,
      new_qualify_form_page: PageNewQualifyFormPageTemplate,
      captive_vs_microcaptive_page: PageCaptiveVsMicroCaptiveLandingTemplate,
      new_831b_plan_page: Page831bPlanTemplate,
      product_guide_page: ProductGuidePageTemplate
      // Add more mappings as needed for other templates
    }),
  },
];